<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        <h1>
          Ontwikkelen
        </h1>
        <h3>Doe je samen!</h3>
        <v-divider></v-divider>
      </v-col>

      <v-col cols="12" class="mt-4">
        <p class="font-weight-regular">
          Feedback, een terugkoppeling op wat is, gaat je helpen om jouw plannen aan te scherpen en je op het gewenste pad te houden.
        </p>

        <p class="font-weight-regular">
          Een terugkoppeling vragen doe je in deze app in twee stappen:
        </p>
        <ol>
          <li>
            Vraag feedforward om jouw plan aan te scherpen
          </li>
          <li>
            Vraag feedback op de voortgang
          </li>
        </ol>
      </v-col>

      <v-col cols="12" class="mt-2">
        <p class="font-weight-regular">
          Vraag feedback aan:
        </p>

        <v-row no-gutters>
          <v-col cols="3">
            <v-text-field
                v-model="form.person1.firstname"
                label="Voornaam"
                required
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="form.person1.lastname"
                label="Achternaam"
                required
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
                v-model="form.person1.email"
                label="Email"
                required
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
                v-model="form.person2.firstname"
                label="Voornaam"
                required
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="form.person2.lastname"
                label="Achternaam"
                required
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
                v-model="form.person2.email"
                label="Email"
                required
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
                v-model="form.person3.firstname"
                label="Voornaam"
                required
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="form.person3.lastname"
                label="Achternaam"
                required
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
                v-model="form.person3.email"
                label="Email"
                required
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
                v-model="form.person4.firstname"
                label="Voornaam"
                required
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
                v-model="form.person4.lastname"
                label="Achternaam"
                required
            />
          </v-col>
          <v-col cols="5">
            <v-text-field
                v-model="form.person4.email"
                label="Email"
                required
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="mt-2">
        <v-radio-group v-model="form.type">
          <v-radio
              label="Vraag feedforward op mijn individueel ontwikkel plan"
              value="1"
              color="accent"
          ></v-radio>
          <v-radio
              label="Vraag feedback op mijn ontwikkeling en voortgang"
              value="2"
              color="accent"
          ></v-radio>
        </v-radio-group>

        <v-col cols="12" class="text-right py-0">
          <v-btn color="primary" block @click="sendFeedbackRequest" :loading="loading" :disabled="loading">Versturen</v-btn>
        </v-col>
      </v-col>
    </v-row>

    <snackbar-text text="Feedback aanvraag verzonden!" bg-color="green" text-color="white" v-model="showSaved" />
    <snackbar-text :text="error.message" bg-color="red" text-color="white" v-model="error.show" />
  </v-container>
</template>

<script>

import apiClient from "@/services/apiClient";
import SnackbarText from "@/components/snackbar-text";

export default {
  name: "dashboardHome",
  components: {SnackbarText},
  data: () => ({
    loading: false,
    error: {
      show: false,
      message: ""
    },
    showSaved: false,
    form: {
      person1: {
        firstname: null,
        lastname: null,
        email: null,
      },
      person2: {
        firstname: null,
        lastname: null,
        email: null,
      },
      person3: {
        firstname: null,
        lastname: null,
        email: null,
      },
      person4: {
        firstname: null,
        lastname: null,
        email: null,
      },
      type: null,
    },
  }),
  methods: {
    resetForm() {
      this.form = {
        person1: {
          firstname: null,
          lastname: null,
          email: null,
        },
        person2: {
          firstname: null,
          lastname: null,
          email: null,
        },
        person3: {
          firstname: null,
          lastname: null,
          email: null,
        },
        person4: {
          firstname: null,
          lastname: null,
          email: null,
        },
        type: null,
      };
    },
    sendFeedbackRequest() {
      this.loading = true;

      apiClient.post("iop/feedback", this.form).then(response => {
        this.showSaved = true;

        this.resetForm();

        this.loading = false;
      }).catch(error => {
        this.error.message = "Er is een onbekende fout opgetreden.";
        this.error.show = true;
        this.loading = false;
      });
    }
  }
};
</script>
